<template>
	<div>
		<h2>{{ $t('invoice.supplier_solde') }}</h2>

		<div class="row">
			<div class="col-6 form-group">
				<label>{{ $t('invoice.supplier_to_pay') }}</label>
				<input type="text" class="form-control" v-model="invoice_supplier.suppliersocr_balance" disabled>
			</div>

			<div class="col-6 form-group">
				<label>{{ $t('invoice.supplier_amount') }}</label>
				<input type="text" class="form-control" v-model="suppliersocr_amount">
			</div>
		</div>

        <div class="form-group mt-3 text-center">
            <button @click="save" class="btn btn-primary">{{ $t('action.solde_facture') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'credit-card']" /></button>
        </div>
	</div>
</template>

<script type="text/javascript">
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

	export default {
		name: 'Solde',
		mixins: [Invoice, Shutter, ShutterInvoice],
		props: ['invoice_supplier'],
		data () {
			return {
				processing: false,
				suppliersocr_amount: 0
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.suppliersocr_amount = this.invoice_supplier.suppliersocr_balance
			},

			async save() {
				this.processing = true
				await this.soldeInvoiceSupplier(this.invoice_supplier.suppliersocr_id, this.suppliersocr_amount.toString().replace(',', '.'))
				this.processing = false

				this.ok()
				this.shutterPanel().close('invoice-supplier-solde')
			}
		},
	}

</script>